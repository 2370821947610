$pure-white: #ffffff;
$background-dark-blue: #0c1321;
$highlight-blue: #75b7ff;
$subtle-grey: rgb(224 224 224 / 0.7);

body {
  background-color: $background-dark-blue;
  font-family: "Funnel Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  overflow-x: hidden;
}

.pure-white {
  color: $pure-white;
}

.highlight-blue {
  color: $highlight-blue;
}

h1 {
  font-size: 3.125rem;
}

p,
li {
  font-size: 1rem;
}

ul {
  padding-left: 1.5rem;
}

a {
  text-decoration: none;
  color: $subtle-grey;
  position: relative;

  &:hover {
    color: $pure-white;
    transition: 0.3s;
  }
}

.subtle {
  color: $subtle-grey;
}

.home-page-container {
  width: 75vw;
  color: $pure-white;
  position: relative;
  margin: 0px auto;
  padding-top: 180px;
}

.about-page-container,
.experience-page-container,
.projects-page-container {
  width: 85vw;
  color: $pure-white;
  position: relative;
  margin: 0px auto;
  padding-top: 50px;
}

.project-card {
  padding: 25px;

  span {
    position: relative;
    margin-left: 0px;
    transition: margin-left 0.3s;
  }

  &:hover {
    border-radius: 10px;
    backdrop-filter: blur(14px);
    background-color: #111a2e;
    cursor: pointer;
    transition: 0.2s;
    box-shadow:
      rgba(27, 31, 35, 0.04) 0px 0px 0px,
      rgba(255, 255, 255, 0.05) 0px 1px 0px inset;

    span {
      margin-left: 3px;
      transition: margin-left 0.3s;
    }
  }

  img {
    width: 100%;
    height: 165px;
    object-fit: cover;
    margin-bottom: 20px;
  }
}

.social-icons {
  display: flex;

  svg {
    margin-right: 16px;
    font-size: 1.5rem;
  }
}

.navigation {
  ul {
    list-style-type: none;
    padding: 0px;
  }

  li {
    font-size: 3.75rem;

    &:hover {
      color: $pure-white;
      cursor: pointer;
    }
  }
}

.partial-underline-lg {
  &::after {
    content: "";
    position: absolute;
    height: 5px;
    left: 0;
    bottom: 0;
    width: 40px;
    background: $highlight-blue;
    transition: width 0.3s;
  }

  &:hover::after {
    width: 100%;
  }
}

.partial-underline-sm {
  span {
    position: relative;
    margin-left: 0px;
    transition: margin-left 0.3s;
  }

  &:hover {
    span {
      margin-left: 3px;
      transition: margin-left 0.3s;
    }
  }

  &::after {
    content: "";
    position: absolute;
    height: 2px;
    left: 0;
    bottom: 0;
    width: 12px;
    background: $highlight-blue;
    transition: width 0.3s;
  }

  &:hover::after {
    width: 100%;
  }
}

.partial-underline-title::after {
  content: "";
  height: 5px;
  left: 0;
  bottom: 0;
  width: 40px;
  background: $highlight-blue;
  transition: width 0.3s;
  display: block;
}

.about-content,
.experience-content,
.projects-content {
  margin-top: 50px;
}

.headshot {
  width: 400px;
  object-fit: cover;
  height: 400px;
  border-radius: 50%;
  margin: auto;
  display: block;
}

.back-home-button {
  width: 85vw;
  margin: 20px auto;
}

.experience-section {
  height: 10vh;
  position: sticky;
  top: 20px;
}

.experience-container {
  margin-bottom: 50px;
}

.experience-skills {
  display: flex;
  flex-wrap: wrap;

  div {
    color: $highlight-blue;
    background-color: rgb(117 183 255 / 0.2);
    padding: 6px 15px;
    border-radius: 100px;
    margin: 0px 7px 7px 0px;
  }
}

.fade-in {
  opacity: 0;
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.screen-swipe {
  background-color: #000000;
  z-index: 2;
  height: 100vh;
  animation: screenSwipeAnimation ease 0.8s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  position: fixed;
  width: 100vw;
  bottom: 0;
  left: 0;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
    margin-top: 50px;
  }

  100% {
    opacity: 1;
    margin-top: 0px;
  }
}

@keyframes screenSwipeAnimation {
  0% {
    height: 100vh;
  }

  100% {
    height: 0;
  }
}

@media only screen and (max-width: 767px) {
  h1 {
    font-size: 2.875rem;
  }

  p,
  li {
    font-size: 0.9rem;
  }

  .social-icons svg {
    font-size: 1.25rem;
  }

  .navigation li {
    font-size: 3.125rem;
  }

  .home-page-container {
    padding-top: 100px;
  }

  .headshot {
    width: 250px;
    height: 250px;
    display: inline;
  }

  .experience-title {
    position: static;
  }

  .project-card img {
    height: inherit;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  h1 {
    font-size: 3rem;
  }

  p,
  li {
    font-size: 0.95rem;
  }

  .social-icons svg {
    font-size: 1.375rem;
  }

  .navigation li {
    font-size: 3.5rem;
  }

  .experience-title {
    position: static;
  }

  .project-card img {
    height: inherit;
  }

  .headshot {
    display: inline;
  }
}
